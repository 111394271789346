<template>
  <div class="component-statistics">
    <div class="d-none d-xl-block">
      <div>
        <p>GENERAL INFORMATION</p>
        <ProcessingLoader v-if="onLoadingNav" />
        <div v-else class="general-information-wrapper">
          <div class="info-container">
            <span class="icon value"
              ><img :src="dollar_black" alt="" />
              {{ formatter(nav_data.daily_nav) }}</span
            >
            <span class="title">Daily nav</span>
          </div>
          <div class="info-container">
            <span class="d-flex value"
              ><span class="icon"><img :src="dollar_black" alt="" /></span>
              {{ formatter(nav_data.exposure) }} <span class="dash"> / </span>
              {{ formatter(nav_data.exposure_percentage)
              }}<span class="icon"><img :src="percentage_black" alt="" /></span
            ></span>
            <span class="title">Current exposure</span>
          </div>
          <div class="info-container">
            <span class="d-flex value">
              {{ formatter(nav_data.today_returns)
              }}<span class="icon"><img :src="percentage_black" alt="" /></span
            ></span>
            <span class="title">Today’s returns</span>
          </div>
          <div class="info-container">
            <span class="d-flex value" :class="[colorStatus(nav_data.upnl)]"
              ><span class="icon"><img :src="dollar_black" alt="" /></span
              >{{ formatter(nav_data.upnl) }} <span class="dash"> / </span>
              {{ formatter(nav_data.upnl_percentage)
              }}<span class="icon"><img :src="percentage_black" alt="" /></span
            ></span>
            <span class="title">Unrealized PnL</span>
          </div>
        </div>
      </div>

      <div>
        <div class="columns-header">
          <p>PERFORMANCE ACCOUNT</p>
          <p>PERFORMANCE STRATEGY</p>
          <!-- <p>RISK</p>
          <p>METRICS</p> -->
          <p>FEES</p>
        </div>
        <div class="performance-wrapper">
          <div class="column-wrapper performance-column">
            <ProcessingLoader v-if="onLoadingNav" />
            <template v-else>
              <div class="info-container w-100">
                <span class="d-flex value"
                  ><span class="icon"><img :src="dollar_black" alt="" /></span
                  >{{ formatter(nav_data.performance_since_inception) }}
                </span>
                <span class="title">Since inception</span>
              </div>
              <div class="info-container w-100">
                <span class="d-flex value"
                  ><span class="icon"><img :src="dollar_black" alt="" /></span
                  >{{ formatter(nav_data.performance_year_to_date) }}
                </span>
                <span class="title">Year to date</span>
              </div>
              <div class="info-container w-100">
                <span class="d-flex value"
                  ><span class="icon"><img :src="dollar_black" alt="" /></span
                  >{{ formatter(nav_data.performance_month_to_date) }}
                </span>
                <span class="title">Month to date</span>
              </div>
            </template>
          </div>
          <div class="column-wrapper">
            <ProcessingLoader v-if="onLoadingNav" />
            <template v-else>
              <div class="info-container w-100">
                <span class="d-flex value">
                  {{ formatter(nav_data.performance_since_inception_percentage)
                  }}<span class="icon"
                    ><img :src="percentage" alt="" /></span
                ></span>
                <span class="title">Since inception</span>
              </div>
              <div class="info-container w-100">
                <span class="d-flex value">
                  {{ formatter(nav_data.performance_year_to_date_percentage)
                  }}<span class="icon"
                    ><img :src="percentage" alt="" /></span
                ></span>
                <span class="title">Year to date</span>
              </div>
              <div class="info-container w-100">
                <span class="d-flex value">
                  {{ formatter(nav_data.performance_month_to_date_percentage)
                  }}<span class="icon"
                    ><img :src="percentage" alt="" /></span
                ></span>
                <span class="title">Month to date</span>
              </div>
            </template>
          </div>
          <!-- <div class="column-wrapper">
            <ProcessingLoader v-if="onLoadingMaxDrawdown" />
            <template v-else>
              <div class="info-container w-100">
                <span class="d-flex value"
                  >{{
                    formatter(
                      drawdown_data.max_drawdown_since_inception_percentage
                    )
                  }}
                  <span class="icon"> <img :src="percentage" alt="" /></span
                ></span>
                <span class="title">Since inception</span>
              </div>
              <div class="info-container w-100">
                <span class="d-flex value"
                  >{{
                    formatter(
                      drawdown_data.max_drawdown_year_to_date_percentage
                    )
                  }}
                  <span class="icon"> <img :src="percentage" alt="" /></span
                ></span>
                <span class="title">Year to date</span>
              </div>
              <div class="info-container w-100">
                <span class="d-flex value"
                  >{{
                    formatter(
                      drawdown_data.max_drawdown_month_to_date_percentage
                    )
                  }}
                  <span class="icon"><img :src="percentage" alt="" /></span
                ></span>
                <span class="title">Month to date</span>
              </div>
            </template>
          </div>
          <div class="column-wrapper">
            <ProcessingLoader v-if="onLoadingMetrics" />
            <template v-else>
              <div class="info-container w-100">
                <span class="d-flex value"
                  ><span class="icon"><img :src="dollar" alt="" /></span
                  >{{ formatter(metrics_data.high_water_mark) }}
                  <span class="dash"> / </span>
                  {{ formatter(metrics_data.drawdown_high_water_mark)
                  }}<span class="icon"><img :src="percentage" alt="" /></span
                ></span>
                <span class="title">High water mark</span>
              </div>
              <div class="info-container w-100">
                <span class="d-flex value"
                  ><span class="icon"><img :src="dollar" alt="" /></span
                  >{{ formatter(metrics_data.annualized_volatility) }}
                </span>
                <span class="title">Annualized volatility</span>
              </div>
              <div class="info-container w-100">
                <span class="d-flex value"
                  >{{ formatter(metrics_data.sharpe_ratio) }}
                  <span class="icon"> <img :src="percentage" alt="" /></span
                ></span>
                <span class="title">Sharpe Ratio</span>
              </div>
            </template>
          </div> -->
          <div class="column-wrapper">
            <ProcessingLoader v-if="onLoadingFees" />
            <template v-else>
              <div class="info-container w-100">
                <span class="d-flex value"
                  ><span class="icon"><img :src="dollar" alt="" /></span
                  >{{ formatter(fees_data.fee_commission) }}
                  <span class="dash"> / </span>
                  {{ formatter(fees_data.fee_commission_percentage)
                  }}<span class="icon"><img :src="percentage" alt="" /></span
                ></span>
                <span class="title">Commission total</span>
              </div>
              <div class="info-container w-100">
                <span class="d-flex value"
                  ><span class="icon"><img :src="dollar" alt="" /></span
                  >{{ formatter(fees_data.fee_funding) }}
                  <span class="dash"> / </span>
                  {{ formatter(fees_data.fee_funding_percentage)
                  }}<span class="icon"><img :src="percentage" alt="" /></span
                ></span>
                <span class="title">Funding total</span>
              </div>
              <div class="info-container w-100">
                <span class="d-flex value"
                  ><span class="icon"><img :src="dollar" alt="" /></span
                  >{{ formatter(fees_data.fee_total) }}
                  <span class="dash"> / </span>
                  {{ formatter(fees_data.fee_total_percentage)
                  }}<span class="icon"><img :src="percentage" alt="" /></span
                ></span>
                <span class="title">Total </span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="d-block d-xl-none">
      <div class="responsive-container">
        <div>
          <p>GENERAL INFORMATION</p>
          <carousel :perPage="1">
            <slide>
              <div class="general-information-wrapper">
                <div class="info-container">
                  <span class="icon value"
                    ><img :src="dollar_black" alt="" />
                    {{ formatter(nav_data.nav) }}</span
                  >
                  <span class="title">Net asset value</span>
                </div>
                <div class="info-container">
                  <span class="d-flex value"
                    ><span class="icon"
                      ><img :src="dollar_black" alt=""
                    /></span>
                    {{ formatter(nav_data.exposure) }}
                    <span class="dash"> / </span>
                    {{ formatter(nav_data.exposure_percentage)
                    }}<span class="icon"
                      ><img :src="percentage_black" alt="" /></span
                  ></span>
                  <span class="title">Current exposure</span>
                </div>
              </div></slide
            >
            <slide>
              <div class="general-information-wrapper">
                <div class="info-container">
                  <span class="d-flex value">
                    {{ formatter(nav_data.today_returns)
                    }}<span class="icon"
                      ><img :src="percentage_black" alt="" /></span
                  ></span>
                  <span class="title">Today’s returns</span>
                </div>
                <div class="info-container">
                  <span
                    class="d-flex value"
                    :class="[colorStatus(nav_data.upnl)]"
                    ><span class="icon"><img :src="dollar_black" alt="" /></span
                    >{{ formatter(nav_data.upnl) }}
                    <span class="dash"> / </span>
                    {{ formatter(nav_data.upnl_percentage)
                    }}<span class="icon"
                      ><img :src="percentage_black" alt="" /></span
                  ></span>
                  <span class="title">Unrealized PnL</span>
                </div>
              </div>
            </slide>
          </carousel>
        </div>

        <div>
          <p>PERFORMANCE</p>
          <carousel :perPage="1">
            <slide>
              <div class="general-information-wrapper">
                <div class="info-container w-100">
                  <span class="d-flex value"
                    ><span class="icon"><img :src="dollar_black" alt="" /></span
                    >{{ formatter(nav_data.performance_since_inception) }}
                    <span class="dash"> / </span>
                    {{
                      formatter(
                        nav_data.performance_since_inception_percentage
                      )
                    }}<span class="icon"
                      ><img :src="percentage_black" alt="" /></span
                  ></span>
                  <span class="title">Since inception</span>
                </div>
                <div class="info-container w-100">
                  <span class="d-flex value"
                    ><span class="icon"><img :src="dollar_black" alt="" /></span
                    >{{ formatter(nav_data.performance_year_to_date) }}
                    <span class="dash"> / </span>
                    {{ formatter(nav_data.performance_year_to_date_percentage)
                    }}<span class="icon"
                      ><img :src="percentage_black" alt="" /></span
                  ></span>
                  <span class="title">Year to date</span>
                </div>
              </div></slide
            >
            <slide>
              <div class="general-information-wrapper">
                <div class="info-container">
                  <span class="d-flex value"
                    ><span class="icon"><img :src="dollar_black" alt="" /></span
                    >{{ formatter(nav_data.performance_month_to_date) }}
                    <span class="dash"> / </span>
                    {{ formatter(nav_data.performance_month_to_date_percentage)
                    }}<span class="icon"
                      ><img :src="percentage_black" alt="" /></span
                  ></span>
                  <span class="title">Month to date</span>
                </div>
              </div></slide
            >
          </carousel>
        </div>

        <!-- <div>
          <p>Risk</p>
          <carousel :perPage="1">
            <slide>
              <div class="column-wrapper">
                <div class="info-container w-100">
                  <span class="d-flex value"
                    >{{
                      formatter(
                        drawdown_data.max_drawdown_since_inception_percentage
                      )
                    }}
                    <span class="icon"> <img :src="percentage" alt="" /></span
                  ></span>
                  <span class="title">Since inception</span>
                </div>
                <div class="info-container w-100">
                  <span class="d-flex value"
                    >{{
                      formatter(
                        drawdown_data.max_drawdown_year_to_date_percentage
                      )
                    }}
                    <span class="icon"> <img :src="percentage" alt="" /></span
                  ></span>
                  <span class="title">Year to date</span>
                </div>
              </div>
            </slide>
            <slide>
              <div class="column-wrapper">
                <div class="info-container">
                  <span class="d-flex value"
                    >{{
                      formatter(
                        drawdown_data.max_drawdown_month_to_date_percentage
                      )
                    }}
                    <span class="icon"><img :src="percentage" alt="" /></span
                  ></span>
                  <span class="title">Month to date</span>
                </div>
              </div>
            </slide>
          </carousel>
        </div>

        <div>
          <p>Metrics</p>
          <carousel :perPage="1">
            <slide>
              <div class="column-wrapper">
                <div class="info-container w-100">
                  <span class="d-flex value"
                    ><span class="icon"><img :src="dollar" alt="" /></span
                    >{{ formatter(metrics_data.high_water_mark) }}
                    <span class="dash"> / </span>
                    {{ formatter(metrics_data.drawdown_high_water_mark)
                    }}<span class="icon"><img :src="percentage" alt="" /></span
                  ></span>
                  <span class="title">High water mark</span>
                </div>
                <div class="info-container w-100">
                  <span class="d-flex value"
                    ><span class="icon"><img :src="dollar" alt="" /></span
                    >{{ formatter(metrics_data.annualized_volatility) }}
                  </span>
                  <span class="title">Annualized volatility</span>
                </div>
              </div>
            </slide>
            <slide>
              <div class="column-wrapper">
                <div class="info-container">
                  <span class="d-flex value"
                    >{{ formatter(metrics_data.sharpe_ratio) }}
                    <span class="icon"> <img :src="percentage" alt="" /></span
                  ></span>
                  <span class="title">Sharpe Ratio</span>
                </div>
              </div>
            </slide>
          </carousel>
        </div> -->

        <div>
          <p>FEES</p>
          <carousel :perPage="1">
            <slide>
              <div class="column-wrapper">
                <div class="info-container w-100">
                  <span class="d-flex value"
                    ><span class="icon"><img :src="dollar" alt="" /></span
                    >{{ formatter(fees_data.fee_commission) }}
                    <span class="dash"> / </span>
                    {{ formatter(fees_data.fee_commission_percentage)
                    }}<span class="icon"><img :src="percentage" alt="" /></span
                  ></span>
                  <span class="title">Commission total</span>
                </div>
                <div class="info-container w-100">
                  <span class="d-flex value"
                    ><span class="icon"><img :src="dollar" alt="" /></span
                    >{{ formatter(fees_data.fee_funding) }}
                    <span class="dash"> / </span>
                    {{ formatter(fees_data.fee_funding_percentage)
                    }}<span class="icon"><img :src="percentage" alt="" /></span
                  ></span>
                  <span class="title">Funding total</span>
                </div>
              </div>
            </slide>
            <slide>
              <div class="column-wrapper">
                <div class="info-container">
                  <span class="d-flex value"
                    ><span class="icon"><img :src="dollar" alt="" /></span
                    >{{ formatter(fees_data.fee_total) }}
                    <span class="dash"> / </span>
                    {{ formatter(fees_data.fee_total_percentage)
                    }}<span class="icon"><img :src="percentage" alt="" /></span
                  ></span>
                  <span class="title">Total</span>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dollar from "../../../../public/common/dollar.png";
import DollarBlack from "../../../../public/common/dollar-black.png";
import DollarGreen from "../../../../public/common/dollar-green.png";
import DollarRed from "../../../../public/common/dollar-red.png";
import Percentage from "../../../../public/common/percentage.png";
import PercentageBlack from "../../../../public/common/percentage-black.png";
import PercentageGreen from "../../../../public/common/percentage-green.png";
import PercentageRed from "../../../../public/common/percentage-red.png";
import { mapState } from "vuex";
import number_formatter from "../../../helpers/formatters/number_formatter";
import ProcessingLoader from "../../commons/alerts/ProcessingLoader.vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "Statistics",
  data() {
    return {
      dollar: Dollar,
      dollar_black: DollarBlack,
      dollar_green: DollarGreen,
      dollar_red: DollarRed,
      percentage: Percentage,
      percentage_black: PercentageBlack,
      percentage_green: PercentageGreen,
      percentage_red: PercentageRed,
    };
  },
  components: { ProcessingLoader, Carousel, Slide },
  computed: {
    ...mapState("dashboard", [
      "statics_fee",
      "statistics_nav",
      "statics_max_drawdown",
      "statics_metrics",
    ]),
    drawdown_data() {
      return this.statics_max_drawdown && this.statics_max_drawdown._data
        ? this.statics_max_drawdown._data.data
        : null;
    },
    fees_data() {
      return this.statics_fee && this.statics_fee._data
        ? this.statics_fee._data.data
        : null;
    },
    metrics_data() {
      return this.statics_metrics && this.statics_metrics._data
        ? this.statics_metrics._data.data
        : null;
    },
    nav_data() {
      return this.statistics_nav && this.statistics_nav._data
        ? this.statistics_nav._data.data
        : null;
    },
    onLoadingFees() {
      return this.statics_fee._status._on_loading;
    },
    onLoadingMaxDrawdown() {
      return this.statics_max_drawdown._status._on_loading;
    },
    onLoadingMetrics() {
      return this.statics_metrics._status._on_loading;
    },
    onLoadingNav() {
      return this.statistics_nav._status._on_loading;
    },
  },
  methods: {
    colorStatus(value) {
      return value > 0 ? "green" : value < 0 ? "red" : "";
    },
    formatter(number) {
      return number_formatter(number);
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/pages/dashboard/statistics.scss";
</style>
